import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { NotFound as PageQuery, Link, Meta, Menu, SiteSettings } from 'src/queries'
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import { determineLocale, DEFAULT_LOCALE } from 'src/utilities/locale'

const getNodeForLang = (data, lang, prismicType) => {
  const { prismic: { [prismicType]: { edges } = { edges: [] } } = {} } = data
  const { node = {} } = edges.find(e => e.node._meta.lang === lang) || {}
  return node
}

const NotFound = ({data}) => {
  const [lang, setLang] = useState(DEFAULT_LOCALE);
  useEffect(() => {
    async function fetchData() {
      const locale = await determineLocale()
      if (locale) {
        setLang(locale)
      }
    }
    fetchData();
  }, []);

  const { pageContext } = data
  const pageData = getNodeForLang(data, lang, 'allNot_founds')
  const siteSettings = getNodeForLang(data, lang, 'allSite_settingss')
  const menuData = getNodeForLang(data, lang, 'allMenus')

  return (
    <Layout pageContext={{ lang, ...pageContext}} menuData={menuData} siteBannerData={{}} siteSettings={siteSettings}>{
      (pageData && pageData.body) ?
        <Slices data={pageData.body}/> :
        <div style={{margin: "80px 0px", textAlign: 'center'}}>
          <h1>We're sorry, we couldn't find what you are looking for</h1>
        </div>
    }</Layout>
  )
}

NotFound.fragments = [PageQuery, Link, Meta, Menu, SiteSettings]
export default NotFound
export const query = graphql`
  query ($lang: String) {
    prismic {
      allNot_founds(lang: $lang) { ...NotFound }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
    }
  }
`